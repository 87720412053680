<template>
    <v-app>
        <v-main>
            <v-container>
                <!-- Carte de recherche -->
                <v-card class="mb-6">
                    <v-card-title>
                        <h3>CODES DE PARRAINAGE</h3>
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="searchEmail"
                            label="Rechercher par email"
                            prepend-icon="mdi-magnify"
                            clearable
                            @keyup.enter="getReferralData"
                            class="mx-4"
                            hide-details
                        ></v-text-field>
                        <v-btn color="primary" @click="getReferralData">
                            Rechercher
                        </v-btn>
                    </v-card-title>
                </v-card>

                <!-- Affichage des informations -->
                <v-row v-if="referralData">
                    <v-col cols="12" md="3">
                        <v-card elevation="3">
                            <div class="usage"></div>
                            <v-card-title><h3>CODE</h3></v-card-title>
                            <v-card-text>
                                <span class="label">{{ referralData.referralCode }}</span>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-card elevation="3">
                            <div class="free"></div>
                            <v-card-title><h3>UTILISATIONS</h3></v-card-title>
                            <v-card-text>
                                <span class="label">{{ referralData.usageCount }}</span>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-card elevation="3">
                            <div class="blocked"></div>
                            <v-card-title><h3>JETONS GAGNÉS</h3></v-card-title>
                            <v-card-text>
                                <span class="label">{{ referralData.rewardTokensNum }}</span>
                            </v-card-text>
                        </v-card>
                    </v-col>

                    <v-col cols="12" md="3">
                        <v-card elevation="3">
                            <div class="usage"></div>
                            <v-card-title><h3>TOTAL UTILISÉ</h3></v-card-title>
                            <v-card-text>
                                <span class="label">{{ referralData.totalUsage }}</span>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- Tableau des utilisateurs -->
                <v-card v-if="referralData" class="mt-6">
                    <v-card-title>
                        <h4>UTILISATEURS DU CODE</h4>
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Rechercher un utilisateur"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="referralData.users"
                        :search="search"
                        :loading="loading"
                    >
                        <template v-slot:[`item.creationDate`]="{ item }">
                            {{ formatDate(item.creationDate) }}
                        </template>
                    </v-data-table>
                </v-card>
            </v-container>
        </v-main>

        <v-overlay :value="loading">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-overlay>
    </v-app>
</template>

<script>
import { baseUrl } from '@/Config/globalConst';
import axios from 'axios';

export default {
    name: 'ReferralView',
    data: () => ({
        searchEmail: '',
        search: '',
        loading: false,
        referralData: null,
        headers: [
            { text: 'Nom', value: 'name' },
            { text: 'Prénom', value: 'firstname' },
            { text: 'Email', value: 'email' },
            { text: 'Date d\'inscription', value: 'creationDate' }
        ]
    }),

    methods: {
        async getReferralData() {
            if (!this.searchEmail) return;
            
            this.loading = true;
            try {
                const response = await axios.post(baseUrl, {
                    query: `
                        query {
                            getReferralUsage(email: "${this.searchEmail}") {
                                user {
                                    name
                                    firstname
                                    email
                                }
                                totalUsage
                                referralCode
                                usageCount
                                rewardTokensNum
                                users {
                                    name
                                    firstname
                                    email
                                    creationDate
                                }
                            }
                        }
                    `
                });

                this.referralData = response.data.data.getReferralUsage;
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
            } finally {
                this.loading = false;
            }
        },

        formatDate(date) {
            return new Date(date).toLocaleDateString('fr-FR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: bold;
    font-size: 35px;
}

.usage {
    background: orange;
    height: 10px;
}

.blocked {
    background: red;
    height: 10px;
}

.free {
    height: 10px;
    background: green;
}
</style> 