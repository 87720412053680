<template>
  <v-card>
    <v-card-title>
      <span class="title-card">DÉTAIL DU COMPTE</span>
      <v-spacer></v-spacer>
      <small class="text-muted font-weight-italic">inscrit le :  {{ profilUser.creationDate }}</small>
    </v-card-title>
    <v-card-text>
      <v-container>
        <form>
          <!-- Status -->
          <div class="form-group">
            <label for="status">Status</label>
            <input disabled type="text" :value="profilUser.tokensIsBlocked === true ? 'Expiré' : profilUser.tokensIsBlocked === false ? 'Actif' : 'Pas d\'achat éffectué'" class="form-control" id="status">
          </div>
          <v-divider></v-divider>

          <!-- Jetons -->
          <div class="form-group">
            <label for="tokens">Jetons</label>
            <input disabled type="text" :value="profilUser.numTokens" class="form-control" id="tokens">
          </div>
          <v-divider></v-divider>

          <!-- Expiration -->
          <div class="form-group">
            <label for="expiration">Date D"expiration</label>
            <input disabled type="text" :value="profilUser.tokenExpirationDate || 'Non disponible'" class="form-control" id="expiration">
          </div>
          <v-divider></v-divider>
        </form>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "portefeuille",  
  props: {
    profilUser: Object  
  }
}
</script>

<style scoped>
.title-card {
  font-weight: 700;
  font-size: 12px;
}
</style>
