<template>
    <v-card>
        <v-card-title><span class="title-card">DÉTAIL DU COMPTE</span>
            <v-spacer></v-spacer>
            <small class="text-muted font-weight-italic">inscrit le :  {{ profilUser.creationDate }}</small>
        </v-card-title>
        <v-card-text>

            <v-container>
                <form>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Nom</label>
                        <input disabled type="text" :value="profilUser.name" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                    </div>
                    <v-divider></v-divider>

                    <div class="form-group">
                        <label for="exampleInputPassword1">Prenoms</label>
                        <input disabled type="text" :value="profilUser.firstname" class="form-control" id="exampleInputPassword1">
                    </div>
                    <v-divider></v-divider>

                    <div class="form-group">
                        <label for="exampleInputPassword1">E-mail</label>
                        <input disabled type="email" :value="profilUser.email" class="form-control" id="exampleInputPassword1">
                    </div>
                    <v-divider></v-divider>

                    <div class="form-group">
                        <label for="exampleInputEmail1">Date de naissance</label>
                        <input disabled type="text" :value="profilUser.birthDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
                    </div>
                    <v-divider></v-divider>

                    <div class="form-row">
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">Genre</label>
                            <input disabled :value="profilUser.sex" type="email" class="form-control" id="inputEmail4" placeholder="Email">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputPassword4">N° de Telephone</label>
                            <input disabled type="text" :value="profilUser.phoneNumber" class="form-control" id="inputPassword4">
                        </div>
                    </div>
                </form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name:"UserInfo.vue",
    props:{
        profilUser: Object
    },
    mounted() {
    console.log(this.profilUser); 
  }
}
</script>

<style scoped>
    .title-card{
        font-weight: 700;
        font-size: 12px;
    }
</style>