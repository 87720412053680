<template>
  <v-app>
    <v-main>
      <v-row
        class="ml-5"
        justify="center"
        align="center"
        no-gutters
        style="height: 250px"
      >
        <v-col xs="3" md="3" sm="3" lg="4" xl="4">
          <v-row justify="center">
            <div class="logo">
              <v-img
                src="../assets/logoariahA.png"
                height="100"
                width="100"
              ></v-img
              ><br />
            </div>
          </v-row>

          <div class="formulaire">
            <v-text-field
              outlined
              class="adressMailInput"
              color="orange"
              label="Adresse mail"
              v-model.trim="$v.userConnexion.email.$model"
              :error-messages="emailErrors"
            ></v-text-field
            ><br />
            <v-text-field
              :error-messages="passwordErrors"
              color="orange"
              outlined
              class="passwordInput"
              label="Mot de passe"
              v-model.trim="$v.userConnexion.password.$model"
              :type="passwordIsVisible ? 'text' : 'password'"
              @click:append="passwordIsVisible = !passwordIsVisible"
              :append-icon="passwordIsVisible ? 'mdi-eye' : 'mdi-eye-off'"
            ></v-text-field>
            <v-checkbox
              v-model="passwordIsStored"
              color="orange"
              label="Mémoriser mon mot de passe"
            ></v-checkbox>
          </div>

          <v-row justify="center">
            <div class="connexionBtn">
              <v-btn color="#fa983a" @click="checkingAndLogin()"
                ><span class="connexion">CONNEXION</span></v-btn
              >
            </div>
          </v-row>
        </v-col>

        <v-col xs="8" md="8" sm="8" lg="8" xl="8">
          <div><v-img src="../assets/phones.jpg"></v-img></div>
        </v-col>
      </v-row>
    </v-main>

    <v-alert type="success" class="alert alert-succes" dismissible>{{
      successMsg
    }}</v-alert>
    <v-alert type="error" class="alert alert-error" dismissible>{{
      errorMsg
    }}</v-alert>

    <v-overlay :value="overlay">
      <v-progress-circular
        color="orange"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import { administratorMails, baseUrl } from "@/Config/globalConst";
import axios from "axios";
import $ from "jquery";
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "LoginView",

  data() {
    return {
      overlay: false,
      errorMsg: null,
      successMsg: null,

      passwordIsVisible: false,
      passwordIsStored: false,

      userConnexion: {
        email: null,
        password: null,
      },

      authentificationCredentials: {
        tokenAuth: {
          success: null,
        },
      },
    };
  },

  validations: {
    userConnexion: {
      email: { required, email },
      password: { required },
    },
  },

  methods: {
    // STOCKER LES DONNEES DE L'ADMINISTRATEUR POUR LA SECTION MOT DE PASSE OUBLIE
    storeUserParameters() {
      if (this.passwordIsStored === true) {
        if (localStorage.getItem("isStored") === true) {
          localStorage.removeItem("isStored");
        } else {
          localStorage.setItem("isStored", JSON.stringify(this.userConnexion));
        }
      } else {
        localStorage.removeItem("isStored");
      }
    },

    // VERIFIER SI DES PARAMETRES UTILISATEURS EXISTENT
    checkUserParams() {
      if (localStorage.getItem("isStored")) {
        const user = JSON.parse(localStorage.getItem("isStored"));
        this.userConnexion.email = user.email;
        this.userConnexion.password = user.password;
      }
    },

    // CHECKING USERS CONNECTION PARAMETERS AND ALLOW LOGIN USER
    checkingAndLogin() {
      this.$v.$touch();
      if (this.$v.userConnexion.$invalid) {
        this.errorMsg = "Certains champs omis sont requis";
        $(".alert-error").fadeIn();
        setTimeout(() => {
          $(".alert-error").fadeOut();
        }, 4000);
      } else if (
        administratorMails.includes(this.userConnexion.email) === false
      ) {
        this.errorMsg = "Vous n'êtes pas autorisé à cette plateforme";
        $(".alert-error").fadeIn();
        setTimeout(() => {
          $(".alert-error").fadeOut();
        }, 4000);
      } else {
        this.login(this.userConnexion.email, this.userConnexion.password);
      }
    },

    // CONNEXION D'UTILISATEUR ADMINISTRATEUR AU TABLEAU DE BORD
    async login(email, password) {
      this.overlay = true;
      let graphqlQuery = {
        query: `mutation {
                    tokenAuth(email: "${email}", password: "${password}") {
                        success
                        token
                        userLoginHistory{ loginHistoryCode
                    }
                } }`,
      };
      await axios
        .post(baseUrl, graphqlQuery)
        .then((response) => {
          console.log(response);
          if (response.status == 200) {
            if (response.data.data.tokenAuth.success === true) {
              this.storeUserParameters();
              this.$router.push({ name: "Dashboard" });
            } else {
              this.errorMsg = "Verifiez vos identifiants";
              $(".alert-error").fadeIn();
              setTimeout(() => {
                $(".alert-error").fadeOut();
              }, 4000);
            }
          }
        })
        .catch(() => {
          /*this.errorMsg = "Veuillez verifier vos identifiants";
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);*/
        })
        .finally(() => {
          this.overlay = false;
        });
    },
  },

  computed: {
    // GESTION DES CONTRAINTES POUR LES CHAMPS (SAISIE UTILISATEUR)

    emailErrors() {
      if (!this.$v.userConnexion.email.$dirty) return [];
      const errors = [];
      !this.$v.userConnexion.email.required &&
        errors.push("Le champs e-mail est obligatoire");
      !this.$v.userConnexion.email.email &&
        errors.push("Entrer une e-mail valide");
      return errors;
    },

    passwordErrors() {
      if (!this.$v.userConnexion.password.$dirty) return [];
      const errors = [];
      !this.$v.userConnexion.password.required &&
        errors.push("Le champs e-mail est obligatoire");
      return errors;
    },
  },

  mounted() {
    this.checkUserParams();
  },
};
</script>

<style>
.adressMailInput {
  color: grey;
  font-size: 16px;
  font-weight: 500;
}

.passwordInput {
  color: grey;
  font-size: 16px;
  font-weight: 500;
}

.connexion {
  color: white;
}

.alert {
  top: 5%;
  right: 3%;
  z-index: 2040;
  width: 20%;
}

.alert-succes {
  position: fixed;
  display: none;
}

.alert-error {
  position: fixed;
  display: none;
}
</style>
