<template>
    <v-app>
        <v-main>
            <v-sheet class="mx-auto pa-2 pt-6" color="grey-lighten-4">
                <div class="row">
                    <div class="col-sm">
                        <div class="usage"></div>
                        <v-card elevation="3">
                            <v-card-title><h3>TRADUCTION</h3></v-card-title>
                            <v-card-subtitle>Jetons utilisés pour les traductions</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokenTraductionCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ totalCoinUsedForTranslate.length }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>

                    <div class="col-sm">
                        <v-card elevation="3">
                            <div class="usage"></div>
                            <v-card-title><h3>CORRECTION</h3></v-card-title>
                            <v-card-subtitle>Jetons utilisés pour les corrections</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokenCorrectionCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ totalCoinUsedForCorrection.length }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>

                    <div class="col-sm">
                        <v-card elevation="3">
                            <div class="free"></div>
                            <v-card-title><h3>JETONS OFFERTS</h3></v-card-title>
                            <v-card-subtitle>Jetons offerts aux utilisateurs</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokensFreeCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ amountFree }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>
                </div><br>

                <div class="row">
                    <div class="col-sm-4">
                        <div class="blocked"></div>
                        <v-card elevation="3">
                            <v-card-title><h3>JETONS BLOQUES</h3></v-card-title>
                            <v-card-subtitle>Total des jetons bloqués</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokenBlockedCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ amountBlocked }}</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>

                    <div class="col-sm-4">
                        <div class="usage"></div>
                        <v-card elevation="3">
                            <v-card-title><h3>TOTAL UTILISATION EN XOF</h3></v-card-title>
                            <v-card-subtitle>Total des jetons utilisés en XOF</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokenBlockedCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ totalPriceTranslate + totalPriceCorrect }} XOF</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>

                    <div class="col-sm-4">
                        <div class="free"></div>
                        <v-card elevation="3">
                            <v-card-title><h3>TOTAL JETONS DISTRIBUES</h3></v-card-title>
                            <v-card-subtitle>Total des jetons distribué en XOF</v-card-subtitle>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span v-if="isLoadingTokenBlockedCount == true"><v-progress-circular indeterminate></v-progress-circular></span>
                                        <span v-else class="label">{{ totalPriceForFree }} XOF</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </div>
                </div><br>


                

            </v-sheet>
            <v-container fluid>
                <!-- Tableau des achats -->
                <div class="row mb-6">
                    <div class="col">
                        <v-card>
                            <v-card-title>
                                <h4>HISTORIQUE DES ACHATS</h4>
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Rechercher"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-data-table
                                :headers="purchaseHeaders"
                                :items="purchaseHistory"
                                :search="search"
                                :loading="loading"
                            >
                                <template v-slot:[`item.status`]="{ item }">
                                    <v-chip x-small :color="getStatusColor(item.status)">
                                        <span class="type font-weight-bold">{{ item.status }}</span>
                                    </v-chip>
                                </template>
                                <template v-slot:[`item.paymentType`]="{ item }">
                                    <v-chip x-small color="primary">
                                        <span class="type font-weight-bold">{{ item.paymentType }}</span>
                                    </v-chip>
                                </template>
                                <template v-slot:[`item.amount`]="{ item }">
                                    <span :class="{ 'failed-transaction': item.status === 'failed' }">
                                        {{ item.amount }}
                                    </span>
                                </template>
                            </v-data-table>
                        </v-card>
                    </div>
                </div>

                <!-- Tableau des activités -->
                <div class="row">
                    <div class="col">
                        <v-card>
                            <v-card-title>
                                <h4>HISTORIQUE DES ACTIVITÉS</h4>
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="activitySearch"
                                    append-icon="mdi-magnify"
                                    label="Rechercher"
                                    single-line
                                    hide-details
                                ></v-text-field>
                            </v-card-title>
                            <v-data-table
                                :headers="activityHeaders"
                                :items="activityHistory"
                                :search="activitySearch"
                                :loading="loading"
                            >
                                <template v-slot:[`item.transactionType`]="{ item }">
                                    <v-chip x-small :color="getActivityColor(item.transactionType)">
                                        <span class="type font-weight-bold">{{ item.transactionType }}</span>
                                    </v-chip>
                                </template>
                            </v-data-table>
                        </v-card>
                    </div>
                </div>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { baseUrl } from '@/Config/globalConst';
import axios from 'axios';
//import $ from 'jquery';
export default {
    name:"transaction",
    data() {
    return {
        singleSelect: false,
        search: '',           // Pour la recherche globale
        activitySearch: '',   // Pour la recherche des activités

        // États de chargement
        isLoadingTokenTraductionCount: false,
        isLoadingTokenCorrectionCount: false,
        isLoadingTokensFreeCount: false,
        isLoadingTokenBlockedCount: false,
        loading: false,

        // Dialogues
        dialogNotification: false,
        dialogNotificationUser: false,
        dialogNotificationGroupUser: false,
        sendEmailDynamicDialog: false,

        // En-têtes du tableau
        headers: [
            { text: 'Nom', value: 'user.name' },
            { text: 'Prenoms', value: 'user.firstname' },
            { text: 'Type de paiement', value: 'paymentType' },
            { text: 'Libelle', value: 'label' },
            { text: 'Nbre jetons', value: 'amount' },
            { text: 'Prix', value: 'price' },
            { text: 'Date', value: 'timestamp' },
            { text: 'Devise', value: 'currency' },
            { text: 'Statut', value: 'status' }
        ],

        // Données des transactions
        transactionList: [],
        selected: [],

        // Compteurs
        amountFree: 0,
        amountBlocked: 0,
        totalPriceTranslate: 0,
        totalPriceCorrect: 0,
        totalPriceForFree: 0,

        // Tableaux de transactions par type
        totalCoinUsedForTranslate: [],
        totalCoinUsedForCorrection: [],
        totalCoinsForFree: [],
        totalCoinsBlocked: [],
        totalPriceForCorrection: [],
        totalPriceForFreeCoins: [],

        // Nouveaux champs pour les transactions web
        transactionWebList: [],
        combinedTransactions: [],

        // Séparation des transactions en deux tableaux
        purchaseHistory: [],    // Pour les achats
        activityHistory: [],    // Pour les autres activités

        // En-têtes spécifiques pour chaque tableau
        purchaseHeaders: [
            { text: 'Nom', value: 'user.name' },
            { text: 'Prenoms', value: 'user.firstname' },
            { text: 'Type de paiement', value: 'paymentType' },
            { text: 'Prix', value: 'amount' },
            { text: 'Date', value: 'timestamp' },
            { text: 'Devise', value: 'currency' },
            { text: 'Statut', value: 'status' }
        ],
        activityHeaders: [
            { text: 'Nom', value: 'user.name' },
            { text: 'Prenoms', value: 'user.firstname' },
            { text: 'Type d\'activité', value: 'transactionType' },
            { text: 'Libellé', value: 'label' },
            { text: 'Nbre jetons', value: 'amount' },
            { text: 'Date', value: 'timestamp' }
        ]
    }
},

    methods:{
        findUserByEmail(email, allUsers) {
            // Recherche directe par email
            const exactMatch = allUsers[email];
            if (exactMatch) return exactMatch;

            // Si pas de correspondance exacte, on cherche par le nom d'utilisateur (partie avant @)
            const username = email.split('@')[0];
            const possibleMatch = Object.values(allUsers).find(user => {
                const otherUsername = user.email?.split('@')[0];
                return username === otherUsername;
            });

            return possibleMatch || {
                name: email.split('@')[0] || 'N/A',
                firstname: ''
            };
        },

        async getAllTransactions() {
            try {
                this.setLoadingStates(true);
                this.resetCounters();

                const response = await this.fetchTransactionData();
                if (!response?.data?.data) {
                    throw new Error("Données invalides reçues de l'API");
                }

                const { showWebTransaction, allTransactionHistory } = response.data.data;

                // Créer un map des utilisateurs par email
                const userMap = allTransactionHistory.reduce((map, transaction) => {
                    if (transaction.user?.email) {
                        console.log('User data:', {
                            email: transaction.user.email,
                            name: transaction.user.name,
                            firstname: transaction.user.firstname
                        });
                        map[transaction.user.email] = transaction.user;
                    }
                    return map;
                }, {});

                // Traitement des transactions d'achat avec enrichissement des données utilisateur
                this.purchaseHistory = showWebTransaction.edges.map(edge => {
                    console.log('Searching for user:', edge.node.customerEmail);
                    const user = this.findUserByEmail(edge.node.customerEmail, userMap);
                    
                    console.log('Found user:', user);
                    
                    return {
                        ...edge.node,
                        timestamp: new Date(edge.node.createdAt).toLocaleString('fr-FR'),
                        user: {
                            name: user.name,
                            firstname: user.firstname || 'Non renseigné'
                        },
                        amount: parseFloat(edge.node.amount)
                    };
                });

                // Traitement des activités (allTransactionHistory)
                this.activityHistory = allTransactionHistory
                    .filter(transaction => transaction.transactionType !== 'Achat')
                    .map(transaction => ({
                        ...transaction,
                        timestamp: new Date(transaction.timestamp).toLocaleString('fr-FR')
                    }));

                // Calcul des totaux pour les statistiques
                this.calculateActivityTotals();

            } catch (error) {
                console.error('Erreur lors de la récupération des transactions:', error);
            } finally {
                this.setLoadingStates(false);
            }
        },

        setLoadingStates(isLoading) {
            this.loading = isLoading;
            this.isLoadingTokenTraductionCount = isLoading;
            this.isLoadingTokenCorrectionCount = isLoading;
            this.isLoadingTokensFreeCount = isLoading;
        },

        async fetchTransactionData() {
            const graphqlQuery = {
                query: `
                    query {
                        showWebTransaction {
                            edges {
                                node {
                                    id
                                    paymentType
                                    currency 
                                    customerEmail
                                    customerName  
                                    createdAt
                                    status
                                    amount
                                }
                            }
                        }
                        allTransactionHistory {
                            id
                            user {
                                id
                                name
                                firstname
                                email
                            }
                            amount
                            label
                            price
                            transactionType
                            timestamp
                        }
                    }
                `
            };
            return await axios.post(baseUrl, graphqlQuery);
        },

        resetCounters() {
            this.totalPriceTranslate = 0;
            this.totalPriceCorrect = 0;
            this.totalPriceForFree = 0;
            this.amountFree = 0;
            this.amountBlocked = 0;
            this.transactionList = [];
        },

        calculateActivityTotals() {
            const PRICE_PER_TOKEN = 250;

            this.activityHistory.forEach(transaction => {
                switch (transaction.label) {
                    case 'Traduction de CV':
                        this.totalCoinUsedForTranslate.push(transaction);
                        this.totalPriceTranslate += transaction.amount * PRICE_PER_TOKEN;
                        break;
                    case 'Correction de CV':
                        this.totalCoinUsedForCorrection.push(transaction);
                        this.totalPriceCorrect += transaction.amount * PRICE_PER_TOKEN;
                        break;
                    case 'Jeton d\'inscrition !':
                        this.totalCoinsForFree.push(transaction);
                        this.amountFree += transaction.amount;
                        this.totalPriceForFree += transaction.amount * PRICE_PER_TOKEN;
                        break;
                    case 'Jétons Bloqués':
                        this.totalCoinsBlocked.push(transaction);
                        this.amountBlocked += transaction.amount;
                        break;
                }
            });
        },

        getStatusColor(status) {
            switch (status) {
                case 'failed': return 'red';
                case 'successful': return 'green';
                case 'pending': return 'orange';
                default: return 'grey';
            }
        },

        getActivityColor(type) {
            switch (type) {
                case 'Usage': return 'orange';
                case 'Bloqué': return 'red';
                default: return 'green';
            }
        }
    },

    mounted(){
        this.getAllTransactions();
    }
}
</script>
<style>
    .type{
        color:white
    }

    .label{
        font-weight: bold;
        font-size: 35px;
    }

    .usage{
        background: orange;
        height: 10px;
    }

    .blocked{
        background: red;
        height: 10px;
    }

    .free{
        height: 10px;
        background: green;
    }

    .failed-transaction {
        color: red;
    }
</style>
