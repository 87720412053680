<template>

  <v-app id="inspire">
    <v-app-bar>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <v-avatar class="ms-2" color="white" size="32" variant="flat">
          <v-img src="../assets/logoariahA.png"></v-img>
        </v-avatar>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-switch v-model="mode" color="orange" class="mt-7" inset :label="mode == true ? `Mode Production`: `Mode Developpement`"></v-switch>&nbsp;&nbsp;&nbsp;&nbsp;
      
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-on="on" v-bind="attrs" small>
            <v-icon>mdi-bell-ring</v-icon>&nbsp;&nbsp;<span>Envoi de notifications</span>
          </v-btn>
        </template>
        <v-list>
          <v-list-item link @click="sendMailToAllUsersWithoutInformation">
            <v-list-item-icon><v-icon>mdi-account-details</v-icon></v-list-item-icon>
            <v-list-item-title class="font-weight-bold">Aux utilisateurs sans info</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="dialogNotification = true">
            <v-list-item-icon><v-icon>mdi-account-group</v-icon></v-list-item-icon>
            <v-list-item-title class="font-weight-bold">À tous les utilisateurs</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-avatar v-bind="attrs" v-on="on" size="30"><v-img src="../assets/profil.png"></v-img></v-avatar>
        </template>
        <v-list>
          <v-list-item link @click="logout()">
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
            <v-list-item-title class="font-weight-bold">Déconnexion</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img src="../assets/logoariahA.png"></v-img>
          </v-list-item-avatar>
        </v-list-item>
  
        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6">Bonjour</v-list-item-title>
            <v-list-item-subtitle>Bienvenue sur votre tableau de bord</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="deep-orange--text text--accent-4">

          <v-list-item @click="$router.push({path:'/Dashboard'}).catch(() => {})">
            <v-list-item-icon>
              <v-icon color="#ff7102">mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>TABLEAU DE BORD</v-list-item-title>
          </v-list-item><br>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#ff7102">mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>UTILISATEURS</v-list-item-title>
          </v-list-item><br>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="#ff7102">mdi-bell-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>NOTIFICATIONS</v-list-item-title>
          </v-list-item><br>
          
          <!-- <v-list-item @click="$router.push({path:'/Cvs'}).catch(() => {})">
            <v-list-item-icon>
              <v-icon color="#ff7102">mdi-text-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>CV</v-list-item-title>
          </v-list-item><br> -->
          
          <v-list-item link @click="$router.push({path:'/Jetons'}).catch(() => {})">
            <v-list-item-icon>
              <v-icon color="#ff7102">mdi-hand-coin</v-icon>
            </v-list-item-icon>
            <v-list-item-title>JETONS</v-list-item-title>
          </v-list-item><br>

          <v-list-item link @click="$router.push({path:'/Parrainage'}).catch(() => {})">
            <v-list-item-icon>
              <v-icon color="#ff7102">mdi-hand-coin</v-icon>
            </v-list-item-icon>
            <v-list-item-title>CODE DE PARRAINNAGE</v-list-item-title>
          </v-list-item><br>

          <v-list-item link @click="$router.push({path:'/Transaction'}).catch(() => {})">
            <v-list-item-icon>
              <v-icon color="#ff7102">mdi-swap-horizontal</v-icon>
            </v-list-item-icon>
            <v-list-item-title>TRANSACTIONS</v-list-item-title>
          </v-list-item><br>

          <v-list-item link @click="$router.push({path:'/Update'}).catch(() => {})">
            <v-list-item-icon>
              <v-icon color="#ff7102">mdi-swap-horizontal</v-icon>
            </v-list-item-icon>
            <v-list-item-title>MISE À JOUR</v-list-item-title>
          </v-list-item><br>

          <v-list-item @click="$router.push({path:'/Bug'}).catch(() => {})">
            <v-list-item-icon>
              <v-icon color="#ff7102">mdi-bug</v-icon>
            </v-list-item-icon>
            <v-list-item-title>BUGS</v-list-item-title>
          </v-list-item><br>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-dialog v-model="dialogNotification" transition="dialog-top-transition" width="500">
      <v-card>
        <v-card-title>Notification à envoyer</v-card-title>
        <v-card-text>
          <v-row>
            <v-col><v-text-field outlined color="orange" :error-messages="titreError" v-model.trim="$v.titre.$model" label="Titre" dense></v-text-field></v-col>
          </v-row>
          <v-row>
            <v-col><v-textarea outlined color="orange" :error-messages="notificationError" v-model.trim="$v.notificationAlert.$model" label="Message" dense></v-textarea></v-col>
          </v-row>
          <v-row>
            <v-col><v-text-field outlined color="orange" v-model="linkToNotify"  label="Votre lien" placeholder="Domaine uniquement" dense></v-text-field></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn x-small variant="text" color="orange" :disabled="btnSendNotificationToAllUsers==true" @click="sendNotificationToUsers(notificationAlert , titre , linkToNotify)">Envoyer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    
    <v-main>
      <router-view></router-view>
    </v-main>

    <v-footer app color="#ff7102" height="44">
      <v-row>
        <v-col align="center"><span class="font-weight-bold copyright">Ariah©Copyright 2023</span></v-col>
      </v-row>
    </v-footer>


    <v-alert type="success" class="alert alert-success" dismissible>{{ successMsg }}</v-alert>
    <v-alert type="error" class="alert alert-error" dismissible>{{ errorMsg }}</v-alert>

    <v-overlay :value="overlay">
      <v-progress-circular color="orange" indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-app>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import { required } from 'vuelidate/lib/validators';
import { baseUrl } from '../Config/globalConst';
export default {
  name:"AriahHome",
  
  data(){
    return{

      myUrlBase:"",
      mode: true,
      btnSendNotificationToAllUsers:false,

      json_fields : {
        'Nom' : 'node.name',
        'Prenom(s)': 'node.firstname',
        'Date d\'inscription': 'node.creationDate',
        'E-mail': 'node.email',
        'Telephone': 'node.phoneNumber',
      },

      overlay: false,
      
      errorMsg : null,
      successMsg : null,
      warningMsg : null,
      loading : false,
      loadingNotif: false,
      loadingJetons:false,
      dialogUser : false,

      drawer: false,
      group: null,

      singleSelect: false,
      selected: [],

      dialogNotification : false ,

      notificationAlert : null,
      linkToNotify : null,
      titre : null,

      notificationAlertUser : null,
      linkToNotifyUser : null,

      notificationAlertUserGroup : null,
      linkToNotifyUserGroup : null,

      objectDynamic:null,
      titleDynamic : null,
      paragrapheDynamicList : [],

      inputs : [{ message : null}],


      headers:[
        { text: 'Nom', value: 'node.name' },
        { text: 'Prenoms', value: 'node.firstname' },
        { text: 'Date d\'inscription', value: 'node.creationDate' },
        { text: 'E-mail', value: 'node.email' },
        { text: 'N° de Tél', value: 'node.phoneNumber' },
        { text: 'Actions', value: 'actions', sortable:false },
      ],
      search: '',

      headersNotif:[
        { text: 'Reference Notif', value: 'node.notifId' },
        { text: 'Description', value: 'node.description' },
        { text: 'Lien', value: 'node.link' },
        { text: 'Date de creation', value: 'node.creationDate' },
      ],
      searchNotif: '',

      headersJetons:[
        { text: 'Nom', value: 'node.user.name' },
        { text: 'Prenoms', value: 'node.user.firstname' },
        { text: 'email', value: 'node.user.email' },
        { text: 'Nbre de jetons', value: 'node.amount' },
      ],
      searchJetons: '',

      totalUserCount : null ,
      totalTokenPurschasedCount : null,
      totalTokenUsedCount : null,
      userList : [],
      notificationList :[],
      notificationToShow:[],

      jetonList:[],

      lastUserRegisterList : [],


      emailAdmin : null,

      userInfo:{
        userCode : null,
        creationDate: null,
        name: null,
        firstname: null,
        photo:null,
        email:null,
        phoneNumber:null
      },
      tokenUsedByUser: null,
      tokenPurschasedByUser: null,

      allTokensPurchasedByUser : null ,

      isLoadingUserCount : false,
      isLoadingTokenPurchased : false,
      isLoadingTokenUsed : false,

      isLoadingTokenPurchasedByUser:false,
      isLoadingTokenUsedByUser:false
    }
  },

  validations:{

    notificationAlert :{
      required
    },

    linkToNotify :{
      required
    },

    titre:{
      required
    }

  },

  methods:{

    //ENVOIE D'EMAIL DYNAMIQUE
    async sendDynamicEmailToUser(usermail , sujet , titre){
      this.sendEmailDynamicDialog = false
      this.overlay = true
      this.inputs.forEach(element => {
        this.paragrapheDynamicList.push(element.message);
      });
      let graphqlQuery = {
        query : `mutation{
          sendDynamicMail(email: "${usermail}" , sujet:"${sujet}" , titre:"${titre}" , paragraphes:${JSON.stringify(this.paragrapheDynamicList)}){
            success
          }
        }`
      }
      await axios.post(baseUrl, graphqlQuery).then((response) =>{
        if (response.data.data.sendDynamicMail.success === true) {
          this.$swal.fire("Envoi de mail", "E-mail envoyé avec succes","success");
        }
      }).catch((e)=>{
        this.$swal.fire("Echec de l'envoi", e ,"error");
      }).finally(()=>{
        this.overlay = false ;
      })
    },

    sendEmailDialog(usermail){
      this.sendEmailDynamicDialog = true;
      this.userInfo.email = usermail
    },
    
    // AJOUTER UN PARAGRAPHE
    addNewParagraph(){
      this.inputs.push({message: null,})
    },

    deleteParagraph(position){
      this.inputs.splice(position , 1);
    },

    // SUPPRIMER UN COMPTE UTILISATEUR
    async deleteUserAccount(email){
      this.$swal({
        title: "Supprimer cet utilisateur?",
        text: "Êtes vous sure ? Attention cette action est irréversible",
        type: "Warning",
        showCancelButton: true,
        confirmButtonColor: "#c0392b",
        confirmButtonText: "Oui, supprimer!"
      }).then((result) => {
        if (result.value) {
          let graphqlQuery = {
            query : `mutation{
              deleteUserByEmail(email: "${email}"){
                success
              }
            }`
          }
          axios.post(baseUrl, graphqlQuery).then((response) =>{
            if(response.data.data.deleteUserByEmail.success === true){
              this.$swal.fire('Suppression effectuée' , "L'utilisateur supprimée avec succes" , 'success')
              this.getUsersList();
              this.getTotalUserCount();
            }else{
              this.$swal.fire('Suppression non effectuée' , "Echec de la suppression" , 'error')
            }
          })
        }
      })
    },
    

    // TO GET COUNT OF USERS VIA API
    async getTotalUserCount(){
      this.isLoadingUserCount = true
      let graphqlQuery = {
        "query" : "query {userCount}"
      }
      await axios.post(baseUrl, graphqlQuery).then((response) =>{
        if(response.status == 200){
          this.totalUserCount = response.data.data.userCount;
        }
        else{
          this.errorMsg = "Erreur serveur , actualisez"
          $('.alert-error').fadeIn();
          setTimeout(() => {
            $('.alert-error').fadeOut();
          }, 4000);
          this.totalUserCount = 0;
        }
      }).catch((e)=>{
        this.errorMsg = e;
        $('.alert-error').fadeIn();
        setTimeout(() => {
          $('.alert-error').fadeOut();
        }, 4000);
      }).finally(()=>{
        this.isLoadingUserCount = false
      })
    },

    returnDateFormatted(dateFormatted){
      let date = new Date(dateFormatted);
      let jour = date.getDate();
      let mois = date.getMonth() + 1 ;
      let annee = date.getFullYear();


      jour = String(jour).padStart(2 , '0');
      mois = String(mois).padStart(2 , '0');

      let dateFormat = `${annee}-${mois}-${jour}`;
      return dateFormat;
    },
  

    // TO GET ALL PURCHASED TOKEN
    async getTotalTokenPurchased(){
      this.isLoadingTokenPurchased = true
      let graphqlQuery = {
        "query" : "query {totalTokensPurchased}"
      }
      await axios.post(baseUrl, graphqlQuery).then((response) =>{
        if(response.status == 200){
          this.totalTokenPurschasedCount = response.data.data.totalTokensPurchased;
        }
        else{
          this.errorMsg = "Erreur serveur , actualisez"
          $('.alert-error').fadeIn();
          setTimeout(() => {
            $('.alert-error').fadeOut();
          }, 4000);
          this.totalTokenPurschasedCount = 0;
        }
      }).catch((e)=>{
        this.errorMsg = e;
        $('.alert-error').fadeIn();
        setTimeout(() => {
          $('.alert-error').fadeOut();
        }, 4000);
      }).finally(()=>{
        this.isLoadingTokenPurchased = false
      })
    },

    // TO GET ALL TOKEN USED
    async getTotalTokenUsed(){
      this.isLoadingTokenUsed = true;
      let graphqlQuery = {
        "query" : "query{totalTokenUsed}"
      }
      await axios.post(baseUrl, graphqlQuery).then((response) =>{
        if(response.status == 200){
          this.totalTokenUsedCount = response.data.data.totalTokenUsed;
        }
        else{
          this.errorMsg = "Erreur de chargement..."
          $('.alert-error').fadeIn();
          setTimeout(() => {
            $('.alert-error').fadeOut();
          }, 4000);
          this.totalTokenUsedCount = 0;
        }
      }).catch((e)=>{
        this.errorMsg = e;
        $('.alert-error').fadeIn();
          setTimeout(() => {
            $('.alert-error').fadeOut();
          }, 4000);
      }).finally(()=>{
        this.isLoadingTokenUsed = false;
      })
    },

    // TO GET ALL USERS VIA API
    async getUsersList(){
      this.loading = true
      let graphqlQuery = {
        "query" : "query{allUserProfil{edges{node{ userCode name firstname creationDate email photo phoneNumber } } } }"
      }
      await axios.post(this.myUrlBase, graphqlQuery).then((response) =>{
        let date  = new Date();
        let myFinalDate =  date.toISOString().split('T')[0]
        if(response.status == 200){
          response.data.data.allUserProfil.edges.forEach(element => {
            element.node.creationDate = this.returnDateFormatted(element.node.creationDate);
            this.userList.push(element)
          });
          this.userList.forEach(element => {
            if (this.returnDateFormatted(element.node.creationDate) === myFinalDate) {
              this.lastUserRegisterList.push(element);
            }
          });
        }
        else{
          this.userList = [];
        }
      }).catch((e)=>{
        this.errorMsg = e;
        $('.alert-error').fadeIn();
        setTimeout(() => {
          $('.alert-error').fadeOut();
        }, 4000);
      }).finally(()=>{
        this.loading = false
      })
    },

    // TO GET ALL NOTIFICATIONS VIA API
    async getAllNotifications(){
      
      this.loadingNotif = true
      let graphqlQuery = {
        "query" : "query{allStaticNotifications{ edges { node{ notifId description link creationDate } } } }"
      }
      await axios.post(baseUrl, graphqlQuery).then((response) =>{
        if(response.status == 200){
          this.notificationList = response.data.data.allStaticNotifications.edges;
        }
        else{
          this.notificationList = [];
        }
      }).catch((e)=>{
        this.errorMsg = e;
        $('.alert-error').fadeIn();
        setTimeout(() => {
          $('.alert-error').fadeOut();
        }, 4000);
      }).finally(()=>{
        this.loadingNotif = false
      })
    },

    //CHECK DOUBLONS
    async editUserProfil(user , userCode){
      this.userInfo.firstname = user.node.firstname ;
      this.userInfo.creationDate = user.node.creationDate ;
      this.userInfo.name = user.node.name;
      this.userInfo.userCode = user.node.userCode;
      this.userInfo.email = user.node.email;
      this.userInfo.photo = user.node.photo;
      this.userInfo.phoneNumber = user.node.phoneNumber;
      this.getTotalTokenPurschasedByUser(userCode);
      this.getTotalTokenUsedByUser(userCode);
      this.dialogUser = true;
    },

    // TO GET  TOTAL TOKEN BY USER
    async getTotalTokenPurschasedByUser(userCode){
      this.isLoadingTokenPurchasedByUser = true
      let graphqlQuery = {
        "query" : `query { totalTokensPurchasedByUser(userCode: "${userCode}") }`
      }
      await axios.post(baseUrl, graphqlQuery).then((response) =>{
        if(response.status == 200){
          this.tokenPurschasedByUser = response.data.data.totalTokensPurchasedByUser;
        }
        else{
          this.tokenPurschasedByUser = 0;
        }
      }).catch((e)=>{
        this.errorMsg = e;
        $('.alert-error').fadeIn();
        setTimeout(() => {
          $('.alert-error').fadeOut();
        }, 4000);
      }).finally(()=>{
        this.isLoadingTokenPurchasedByUser = false
      })
    },

    //TO GET TOTAL TOKEN USED BY USER
    async getTotalTokenUsedByUser(userCode){
      this.isLoadingTokenUsedByUser = true
      let graphqlQuery = {
        "query" : `query { totalTokenUsedByUser(userCode: "${userCode}") }`
      }
      await axios.post(baseUrl, graphqlQuery).then((response) =>{
        if(response.status == 200){
          this.tokenUsedByUser = response.data.data.totalTokenUsedByUser;
        }
        else{
          this.tokenUsedByUser = 0;
        }
      }).catch((e)=>{
        this.errorMsg = e;
        $('.alert-error').fadeIn();
        setTimeout(() => {
          $('.alert-error').fadeOut();
        }, 4000);
      }).finally(()=>{
        this.isLoadingTokenUsedByUser = false
      })
    },

    //LOGOUT SESSION
    logout(){
      this.$router.replace({name:"home"})
    },

    close(){
      this.notificationAlert = null;
      this.linkToNotify = null;
      this.titre = null
      this.dialogNotification = false
    },


    // CREATE NOTIFICATION FOR USER
    async createNotification(description, link , userCode){
      let graphqlQuery = {
        query : `mutation{
          sendStaticNotification(description: "${description}",  link: "https://${link}" , userCode: "${userCode}"){
            success
          }
        }`
      }
      await axios.post(baseUrl, graphqlQuery).then((response) =>{
        if(response.data.data.sendStaticNotification.success === true){
          //this.$swal.fire('Notification envoyée' , 'Opération effectuée avec succes' , 'success')
        }else{
          //this.$swal.fire('Envoi de la notification echoué' , '' , 'error')
        }
      }).catch((e)=>{
        this.$swal.fire('Notification non enregistrée' , e , 'error')
      }).finally(()=>{
        this.getAllNotifications()
      })
    },

    //ENVOYER UNE NOTIFICATION A TOUS LES UTILISATEURS
    async sendNotificationToUsers(description,titre, link){
      this.$v.$touch()
      if(this.$v.notificationAlert.$invalid){
        this.errorMsg = "Certains champs omis sont requis"
        $('.alert-error').fadeIn();
        setTimeout(() => {
          $('.alert-error').fadeOut();
        }, 4000);
      }else if(this.$v.titre.$invalid){
        this.errorMsg = "Certains champs omis sont requis"
        $('.alert-error').fadeIn();
        setTimeout(() => {
          $('.alert-error').fadeOut();
        }, 4000);
      }
      else{
        if(this.linkToNotify != null){
          if(this.linkToNotify.includes("https://")){
            this.$swal.fire('Lien corrompu' , 'Entrer seulement le domaine', 'error')
          }else{
            
            this.mutationToSendNotificationToAllUsers(description , titre , link);
          }
        }else if(this.linkToNotify == null){
          link = "";
          this.mutationToSendNotificationToAllUsers(description , titre , link);
        }else{
          this.mutationToSendNotificationToAllUsers(description , titre , link);
        }
      }
    },

    async mutationToSendNotificationToAllUsers(description , titre , link){
      this.btnSendNotificationToAllUsers = true
      let graphqlQuery = {
        query: `mutation{
          sendNotificationToAllUsers(cat:"Systeme" , body:"${description}", title :"${titre}" ,  link:"https://${link}"){
            success
          }
        }`
      }
      await axios.post(baseUrl, graphqlQuery).then((response) =>{
        if(response.data.data.sendNotificationToAllUsers == null){
          this.$swal.fire('Certains utilisateurs n\'ont pas reçu la notification' , 'Erreur du systeme', 'warning')
        }
        if (response.data.data.sendNotificationToAllUsers.success === true) {
          this.createNotification(description, link);
          this.$swal.fire('Notification envoyée' , 'Opération effectuée avec succes' , 'success')
          this.close()
          this.getAllNotifications()
        }else{
          this.$swal.fire('Envoi de la notification echoué' , '' , 'error')
        }
      }).catch((e)=>{
        this.$swal.fire('Notification non envoyee' , e , 'error')
      }).finally(()=>{
        this.btnSendNotificationToAllUsers = false
        this.getAllNotifications();
      })
    },


    // ENVOI DE MAIL À TOUS LES UTILISATEURS SANS INFORMATIONS PERSONNELLE
    async sendMailToAllUsersWithoutInformation(){
      this.overlay = true
      let graphqlQuery = {
        query: `mutation{ sendEmailToUsersNull{
              success
            }
        }`
      }
      await axios.post(baseUrl, graphqlQuery).then((response) =>{
        if (response.data.data.sendEmailToUsersNull.success === true) {
          this.$swal.fire('Mail envoyée' , "Les utilisateurs ont bien recu leur mail" , 'success')
        }
      }).catch((e)=>{
        this.$swal.fire('Mail non envoyee' , e , 'error')
      }).finally(()=>{
        this.overlay = false
      })
    },

    openDialog(){
      if(this.selected.length == 0){
        this.$swal.fire('Aucun compte selectionné' , '' , 'error')
      }else{
        this.dialogNotificationGroupUser = true
      }
    },

  },

  computed:{
    //CONTRAINTES POUR LES CHAMPS DE SAISIE DE NOTIFICATION
    notificationError(){
      if (!this.$v.notificationAlert.$dirty) return [];
      const errors =  [] ;
      !this.$v.notificationAlert.required && errors.push("Le champs notification est obligatoire");
      return errors ;
    },

    linkError(){
      if (!this.$v.linkToNotify.$dirty) return [];
      const errors =  [] ;
      !this.$v.linkToNotify.required && errors.push("Entrer un lien");
      return errors ;
    },

    titreError(){
      if (!this.$v.titre.$dirty) return [];
      const errors =  [] ;
      !this.$v.titre.required && errors.push("Le titre est obligatoire");
      return errors ;
    },

  },


  mounted(){
    this.getAllNotifications();
    this.getTotalUserCount();
    this.getTotalTokenPurchased();
    this.getTotalTokenUsed();
  }

}
</script>

<style scoped>
  .userCount{
    font-weight: bold;
    font-size: 35px;
  }

  .text{
    color: black;
    font-weight: bold;
    font-size: 15px;
    opacity: 0.5
  }

  .copyright{
    color:white
  }

  .alert{
    z-index: 2100;
    width: 20%;
  }

  .alert-success{
    position: fixed;
    display: none;
    right: 0;
  }

  .alert-error{
    position: fixed;
    display: none;
    right: 0;
  }

  .lastRegister{
    font-size: 60px;
  }
</style>
