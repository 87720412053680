import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../components/LoginView.vue'
import TableauBordView from '../components/TableauBord.vue'
import TransactionView from '../components/Transaction.vue'
import HomeView from '../views/HomeView.vue'
import BugView from '../components/Bugs.vue'
import CvView from '../components/CvInfos.vue'
import CvDetailView from '../components/CvItemDetail.vue'
import JetonsView from '../components/Jetons.vue'
import parrainageView from '../components/parrainage.vue'
import ReferralView from '../components/Referral.vue'
import UpdateView from '../components/Update.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/homeView',
    name: 'homeView',
    component: HomeView,
    children : [
      {
        path: '/Dashboard',
        name: 'Dashboard',
        component: TableauBordView,
      },
      {
        path: '/Transaction',
        name: 'Transaction',
        component: TransactionView,
      },
      {
        path: '/Bug',
        name: 'Bug',
        component: BugView,
      },
      {
        path: '/Jetons',
        name: 'Jetons',
        component: JetonsView
      },
      {
        path: '/Parrainage',
        name: 'Parrainage',
        component: parrainageView
      },
      {
        path: '/Update',
        name: 'Update',
        component: UpdateView
      },
      {
        path: '/Cvs',
        name: 'Cv',
        component: CvView,
      },
      {
        path: '/CvDetail/:cvModel',
        name: 'CvDetail',
        component: CvDetailView,
      },
      {
        path: '/referral',
        name: 'Referral',
        component: ReferralView
      }
    ]
  },
  
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
