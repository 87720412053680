<template>
    <v-app>
        <v-container>
            <v-card>
                <v-card-title><span>GESTION DES JETONS</span>
                    <v-spacer></v-spacer>
                    <v-btn x-small color="primary" @click="openBox">AJOUTER UN MODÈLE</v-btn>
                </v-card-title>
                <v-card-subtitle>Liste des Modèles disponibles</v-card-subtitle>
                <v-data-table
                    :headers="headers"
                    :items="AppsertList"
                    :loading="loading"
                    >
                    <!-- Exemple pour une colonne de date spécifique -->
                    <template v-slot:[`item.createdAt`]="{ item }">
                        {{ formatDate(item.createdAt) }}
                    </template>

                    <!-- Slot pour formater la date de création -->
                    <template v-slot:[`item.creationDate`]="{ item }">
                        {{ formatDate(item.creationDate) }}
                    </template>

                    <template v-slot:[`item.actions`]="{item}">
                        <v-icon
                        title="Editer"
                        color="blue"
                        class="mr-3"
                        small
                        @click="editAppSet(item)"
                        >
                        mdi-pencil-outline
                        </v-icon>
                        <!-- <v-icon
                        title="Supprimer"
                        color="red"
                        class="mr-3"
                        small
                        @click="deleteAppSet(item.model)"
                        >
                        mdi-delete
                        </v-icon> -->
                    </template>
                </v-data-table>
            </v-card>
        </v-container><br>

        <v-container>
            <v-card width="500" class="box_token">
                <v-card-title><small>Gérer vos modèles</small>
                    <v-spacer></v-spacer>
                    <v-btn x-small icon @click="closeBox"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle>
                    <span>Ajouter et Modifier vos modèles pour faciliter l'administration.</span>
                </v-card-subtitle>
                
                <v-card-text>
                    <form>
                        <div class="mb-3 col-lg-6">
                            <label for="exampleInputEmail1" class="form-label">Modele</label>
                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" v-model="tokenOject.model">
                        </div>

                        <div class="mb-3 col-lg-6">
                            <label for="exampleInputPassword1" class="form-label">Version</label>
                            <input type="text" class="form-control" id="exampleInputPassword1" v-model="tokenOject.version">
                        </div>
                        <div class="mb-3 col-lg-6">
                            <label for="exampleInputPassword1" class="form-label">URL de Mise à Jour</label>
                            <input type="text" class="form-control" id="exampleInputPassword1" v-model="tokenOject.updateUrl">
                        </div>

                        <div class="col-lg-5">
                            <v-btn v-if="createMode == true" x-small color="success" 
                                @click="createAppsert()">
                                <span class="font-weight-bold">AJOUTER LE MODELE</span>
                            </v-btn>
                            <v-btn v-else x-small color="success" @click="updateAppSet(tokenOject.appSetCode, tokenOject.model, tokenOject.updateUrl, tokenOject.version)">
                                <span class="font-weight-bold">MODIFIER LA VERSION</span>
                            </v-btn>
                        </div>
                        
                    </form>
                </v-card-text>
            </v-card>
        </v-container>

        <v-alert type="success" class="alert alert-success" dismissible>{{ successMsg }}</v-alert>
        <v-alert type="error" class="alert alert-error" dismissible>{{ errorMsg }}</v-alert>

        <v-overlay :value="overlay">
            <v-progress-circular color="orange" indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-app>
</template>


<script>
import axios from 'axios';
import $ from 'jquery'
import { baseUrl } from '@/Config/globalConst';
export default{
    name: "UpdateView",
    data(){
        return{
            AppsertList : [],

            createMode: false,

            overlay:false,

            errorMsg : null,

            successMsg : null,

            warningMsg : null,

            tokenOject:{
                version:null,
                model:null,
                updateUrl:null
            },

            loading: false,

            search:"",

            headers: [
                { 
                    text: 'Modèle', 
                    value: 'model' 
                },
                { 
                    text: 'Version', 
                    value: 'version' 
                },
                { 
                    text: 'Nombre de Devices', 
                    value: 'nbDevices' 
                },
                { 
                    text: 'URL de Mise à Jour', 
                    value: 'updateUrl' 
                },
                { 
                    text: 'Date de Création', 
                    value: 'creationDate' 
                },
                { 
                    text: 'Actions', 
                    value: 'actions', 
                    sortable: false 
                }
            ],
        }
    },

    methods:{

        //Formatage de date de création
        formatDate(dateString) {
            if (!dateString) return '';
            const date = new Date(dateString);
            return date.toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
            }).replace(/\//g, '-');
        },

        //Modifier un token(Service Web)
        async updateAppSet(appSetCode, model, updateUrl, version) {
    this.overlay = true;
    let graphqlQuery = {
        query: `mutation {
            updateAppSet(
                appSetCode: "${appSetCode}", 
                model: "${model}", 
                updateUrl: "${updateUrl}", 
                version: "${version}"
            ) {
                success
                error
            }
        }`
    };

    try {
        const response = await axios.post(baseUrl, graphqlQuery);
        const result = response.data.data.updateAppSet;
        
        if (result.success) {
            this.$swal.fire({
                icon: 'success', 
                title: 'Modification effectuée',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            });
            this.getAllAppsert();
        } else {
            this.$swal.fire({
                icon: 'error', 
                title: 'Erreur de mise à jour',
                text: result.error,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000
            });
        }
    } catch (e) {
        this.$swal.fire("Échec de l'envoi", e.toString(), "error");
    } finally {
        this.overlay = false;
        $('.box_token').fadeOut(800);
    }
},

        //Créer un modèle
        async createAppsert() {
            this.overlay = true;
            let graphqlQuery = {
                query: `mutation {
                createAppSet(model: "${this.tokenOject.model}", version: "${this.tokenOject.version}", updateUrl: "${this.tokenOject.updateUrl}") {
                    success
                    error
                }
                }`
            };

            try {
                const response = await axios.post(baseUrl, graphqlQuery);
                
                if (response.data.data.createAppSet.success === true) {
                this.successMsg = "Opération Réussie";
                console.log(response);
                $('.alert-success').fadeIn();
                setTimeout(() => {
                    $('.alert-success').fadeOut();
                }, 4000);
                this.getAllAppsert();
                } else {
                this.errorMsg = response.data.data.createAppSet.error;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
                }
            } catch (e) {
                this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                $('.alert-error').fadeOut();
                }, 4000);
            } finally {
                this.overlay = false;
                setTimeout(() => {
                $('.box_token').fadeOut();
                }, 800);
            }
        },

        //Supprimer un token(Service Web)
        async deleteToken(label){
            this.$swal({
                    title: "Voulez vous supprimer ?", text: "Êtes vous sure ? Attention cette action est irréversible",
                    type: "Warning", showCancelButton: true,
                    confirmButtonColor: "#c0392b",confirmButtonText: "Oui, supprimer!"
                }).then((result) => {
                this.overlay = true;
                if (result.value) {
                    let graphqlQuery = {
                        query : `mutation{
                            deletePrice(label:"${label}"){
                                success
                                error
                            }
                        }`
                    }
                    axios.post(baseUrl, graphqlQuery).then((response) =>{
                        if(response.data.data.deletePrice.success === true){
                            this.$swal.fire('Suppression reussie' , "L' utilisateur a été rechargé"  , 'success')
                        }else{
                            this.$swal.fire('Echec de la suppression' , response.data.data.deletePrice.error , 'error')
                        }
                    }).catch((e)=>{
                        this.$swal.fire('Echec de la suppression' , e , 'error')
                    }).finally(()=>{
                        this.overlay = false
                        this.getAllTokens();
                    })
                }
            })
        },

        //Modifier un modèle
        editAppSet(item){
            this.createMode = false;
            this.tokenOject.appSetCode = item.appSetCode
            this.tokenOject.model = item.model
            this.tokenOject.version = item.version
            this.tokenOject.updateUrl = item.updateUrl
            setTimeout(() => {
                $('.box_token').fadeIn();
            }, 1000)
        },

        // Recuperer la liste des tokens disponibles
        async getAllAppsert(){
            this.loading = true
            let graphqlQuery = {
            query: `query {
                allAppSet {
                    edges {
                    node {
                        appSetCode
                        model
                        version
                        nbDevices
                        updateUrl
                        creationDate
                    }
                    }
                }
                }`
            };

            await axios.post(baseUrl, graphqlQuery).then((response) =>{
                if(response.data && response.data.data && response.data.data.allAppSet){
                    this.AppsertList = response.data.data.allAppSet.edges.map(edge => edge.node);
                    console.log(this.AppsertList);
                }
                else{
                    this.AppsertList = [];
                    console.log(this.AppsertList);
                }
            }).catch((e)=>{
                this.errorMsg = e;
                $('.alert-error').fadeIn();
                setTimeout(() => {
                    $('.alert-error').fadeOut();
                }, 4000);
            }).finally(()=>{
                this.loading = false
            })
        },
        
        // OUVERTURE DE LA BOX DES TOKENS
        openBox(){
            this.tokenOject.label = null
            this.tokenOject.price = null
            this.createMode = true
            setTimeout(() => {
                $('.box_token').fadeIn();
            }, 800);
        },

        // FERMETURE DE LA BOX DES TOKENS
        closeBox(){
            setTimeout(() => {
                $('.box_token').fadeOut();
            }, 800);
        }
    },


    mounted(){
        this.getAllAppsert();
    }
}

</script>


<style>
    .box_token{
        display: none;
    }

    .alert{
        z-index: 2100;
        width: 20%;
    }

    .alert-success{
        position: fixed;
        display: none;
        right: 0;
    }

    .alert-error{
        position: fixed;
        display: none;
        right: 0;
    }
</style>