<template>
    <v-app>
        <v-main>
            <v-container>
                <div class="row">
                    <div class="col-lg-3">
                        <span>Total des téléchargements: {{ downloadCount }}</span>
                    </div>
                    <div class="col-lg-3">
                        <span>Total des Modifications: {{ updateCount }}</span>
                    </div>
                    <div class="col-lg-3">
                        <span>Total des Partages: {{ shareCount }}</span>
                    </div>
                </div>
            </v-container>
            <v-container>
                <span class="title-card">Cv et informations</span><br><br>
                <div class="row">
                    <div class="col-lg-3" v-for="(cv , c) in cvList" :key="c">
                        <v-card elevation="2" hover>
                            <v-img height="380" :src="cv.image"></v-img>
                            <v-card-text>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-subtitle>CV Model :</v-list-item-subtitle>
                                            </v-list-item-content>
                    
                                            <v-list-item-content>
                                                <v-list-item-subtitle class="font-weight-bold">{{ cv.groupKey }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn x-small color="warning" @click="getAllCvInfosUserByModel(cv.groupKey)"><span>voir plus</span></v-btn>
                            </v-card-actions>
                        </v-card>
                    </div>
                </div>
            </v-container>
        </v-main>
        <v-overlay :value="overlay">
            <v-progress-circular color="orange" indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-alert type="success" class="alert alert-success" dismissible>{{ successMsg }}</v-alert>
        <v-alert type="error" class="alert alert-error" dismissible>{{ errorMsg }}</v-alert>
    </v-app>
</template>

<script>
import axios from 'axios';
//import $ from "jquery"
import { baseUrl, cvInfosByUser } from '../Config/globalConst.js'
export default {
    name:"CvInfos",
    data(){
        return{
            errorMsg: null,
            successMsg: null,
            warningMsg: null,

            downloadCount: 0,
            shareCount: 0,
            updateCount: 0,

            overlay: false,

            cvList:[
                { groupKey:"001", image : null,},{ groupKey:"002", image : null,},{ groupKey:"003", image : null,},
                { groupKey:"004", image : null,},{ groupKey:"005", image : null,},{ groupKey:"006", image : null,},
                { groupKey:"007", image : null,},{ groupKey:"008", image : null,},{ groupKey:"009", image : null,},
                { groupKey:"010", image : null,},{ groupKey:"011", image : null,},{ groupKey:"012", image : null,},
                { groupKey:"013", image : null,},{ groupKey:"014", image : null,},{ groupKey:"015", image : null,},
                { groupKey:"016", image : null,},{ groupKey:"017", image : null,},{ groupKey:"018", image : null,},
                { groupKey:"019", image : null,},{ groupKey:"020", image : null,},
            ],
            loading : false,
            search : '',
            singleSelect : false,

            selected:[],
        }
    },

    methods: {

        // VOIR LA LISTE DES UTILISATEURS AYANT UTILISÉS UN MODEL
        async getAllCvInfosUserByModel( cvModel){
            this.$router.push({name:'CvDetail' , params:{cvModel:cvModel}})
        },
        
        
        // OBTENIR LA LISTE DES CV PAR MODELS
        async getAllCvInfosModel(){
            this.cvList.forEach(element => {
                if(element.groupKey == "001") {
                    element.image = "https://images.ariahapp.com/support/images/001.jpg"
                }else if(element.groupKey == "002"){
                    element.image ="https://images.ariahapp.com/support/images/002.jpg"
                }else if(element.groupKey == "003"){
                    element.image ="https://images.ariahapp.com/support/images/003.jpg"
                }else if(element.groupKey == "004"){
                    element.image ="https://images.ariahapp.com/support/images/004.jpg"
                }else if(element.groupKey == "005"){
                    element.image ="https://images.ariahapp.com/support/images/005.jpg"
                }else if(element.groupKey == "006"){
                    element.image ="https://images.ariahapp.com/support/images/006.jpg"
                }else if(element.groupKey == "007"){
                    element.image ="https://images.ariahapp.com/support/images/007.jpg"
                }else if(element.groupKey == "008"){
                    element.image ="https://images.ariahapp.com/support/images/008.jpg"
                }else if(element.groupKey == "009"){
                    element.image ="https://images.ariahapp.com/support/images/009.jpg"
                }else if(element.groupKey == "010"){
                    element.image ="https://images.ariahapp.com/support/images/010.jpg"
                }else if(element.groupKey == "011"){
                    element.image ="https://images.ariahapp.com/support/images/011.jpg"
                }else if(element.groupKey == "012"){
                    element.image ="https://images.ariahapp.com/support/images/012.jpg"
                }else if(element.groupKey == "013"){
                    element.image ="https://images.ariahapp.com/support/images/013.jpg"
                }else if(element.groupKey == "014"){
                    element.image ="https://images.ariahapp.com/support/images/014.jpg"
                }else if(element.groupKey == "015"){
                    element.image ="https://images.ariahapp.com/support/images/015.jpg"
                }else if(element.groupKey == "016"){
                    element.image ="https://images.ariahapp.com/support/images/016.jpg"
                }else if(element.groupKey == "017"){
                    element.image ="https://images.ariahapp.com/support/images/017.jpg"
                }else if(element.groupKey == "018"){
                    element.image ="https://images.ariahapp.com/support/images/018.jpg"
                }else if(element.groupKey == "019"){
                    element.image ="https://images.ariahapp.com/support/images/019.jpg"
                }else{
                    element.image ="https://images.ariahapp.com/support/images/020.jpg"
                }
            });
        },

        // TOTAL DES COMPTES SUR LES OPERATIONS
        async getAllCountOperations() {
        try {
            // Initialisation des compteurs
            this.downloadCount = 0;
            this.shareCount = 0;
            this.updateCount = 0;

            // Utilisation de Promise.all pour gérer toutes les requêtes en parallèle
            const promises = this.cvList.map(element => {
                const graphqlQuery = {
                    query: cvInfosByUser("cv_model", element.groupKey)
                };
               // Configuration Axios avec les en-têtes
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            };
            
            // Envoi de la requête avec Axios
            return axios.post(baseUrl, graphqlQuery,config);
        });


            const responses = await Promise.all(promises);

            // Traitement des réponses
            responses.forEach(response => {
                if (response.status === 200 && response.data.data.cvInfosByUser[0]) {
                    response.data.data.cvInfosByUser[0].userCounts.forEach(item => {
                        this.downloadCount += item.downloadCount;
                        this.shareCount += item.shareCount;
                        this.updateCount += item.updateCount;
                    });
                }
            });
        } catch (error) {
            console.error("Erreur lors de la récupération des compteurs:", error);
            console.error("Erreur détaillée:", error.response?.data || error.message);
        }
    }

    },

    mounted(){
        this.getAllCvInfosModel();
        this.getAllCountOperations()
    }
}
</script>

<style scoped>
    .title-card{
        font-size: 18px;
        color: #2f3542;
        font-weight: bold;
    }

    .alert{
        z-index: 2100;
        width: 20%;
    }

    .alert-success{
        position: fixed;
        display: none;
        right: 0;
    }

    .alert-error{
        position: fixed;
        display: none;
        right: 0;
    }

    .alert-warning{
        position: fixed;
        display: none;
        right: 0;
    }

    .libelle-fois{
        color: #130f40;
    }
</style>